<template>
  <b-row>
    <!-- <b-col md="4">
      <b-card
        :img-src="require('@/assets/images/platforms/messenger.png')"
        img-top
        title="Messenger"
      >
        <hr />
        <b-card-text>粉絲專頁：未連接</b-card-text>
        <b-button
          @click="fbLogin"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
        >
          連接粉絲專頁
        </b-button>
      </b-card>
    </b-col>
    <b-col md="4">
      <b-card
        :img-src="require('@/assets/images/platforms/ig.png')"
        img-top
        title="Instagram"
      >
        <hr />
        <b-card-text>商業帳號：未連接</b-card-text>
        <b-button
          @click="igLogin"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
        >
          連接商業帳號
        </b-button>
      </b-card>
    </b-col> -->
    <b-col md="4">
      <b-card
        :img-src="require('@/assets/images/platforms/line.png')"
        img-top
        title="Line 官方帳號"
      >
        <hr />
        <b-card-text>官方帳號：由 FLOW 管理</b-card-text>
        <!-- <b-button
          @click="igLogin"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
        >
          連接商業帳號
        </b-button> -->
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import axios from "@axios";
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { onMounted } from "@vue/composition-api";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
  },
  directives: {
    Ripple,
  },
  setup() {
    window.fbAsyncInit = function () {
      FB.init({
        appId: "1238163093450269",
        cookie: true,
        xfbml: true,
        version: "v16.0",
      });

      FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    const fbLogin = () => {
      FB.getLoginStatus((result) => {
        checkLogin(result.status);

        FB.login(
          function (response) {
            if (response.status !== "connected") {
              return;
            }

            axios
              .post("api/FbLogin/Login/", response)
              .then((response) => {})
              .catch((error) => reject(error));
          },
          {
            config_id: "953776489364314",
          }
        );
      });
    };

    const igLogin = () => {
      FB.getLoginStatus((result) => {
        checkLogin(result.status);

        FB.login(function () {}, {
          config_id: "741367024317781",
        });
      });
    };

    function checkLogin(loginStatus) {
      if (loginStatus === "connected") {
        FB.logout();
      }
    }

    return { fbLogin, igLogin };
  },
};
</script>
